import React, { useEffect } from 'react';
import { Edit2, Save, X, Plus, Trash2 } from 'lucide-react';
import { useAdminStore } from '../store/adminStore';
import LoginModal from './LoginModal';

const AdminControls: React.FC = () => {
  const { isAdmin, logout, toggleEditMode, editMode } = useAdminStore();
  const [showLogin, setShowLogin] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  useEffect(() => {
    // Wait for the store to be initialized
    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // Check for Ctrl/Cmd + Shift + L
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === 'l') {
        e.preventDefault(); // Prevent default browser behavior
        console.log('Login shortcut triggered!'); // Debug log
        setShowLogin(true);
      }
    };

    // Add event listener with capture phase to ensure it catches the event first
    window.addEventListener('keydown', handleKeyPress, true);
    
    // Cleanup
    return () => window.removeEventListener('keydown', handleKeyPress, true);
  }, []);

  const handleLogout = () => {
    logout();
  };

  if (!isInitialized) {
    return null;
  }

  if (!isAdmin) {
    return <LoginModal isOpen={showLogin} onClose={() => setShowLogin(false)} />;
  }

  return (
    <>
      <div className="fixed bottom-6 right-6 flex items-center gap-2 z-50">
        <button
          onClick={toggleEditMode}
          className={`
            px-4 py-2 rounded-lg font-medium text-sm
            ${editMode === 'edit' 
              ? 'bg-amber-500 text-white' 
              : 'bg-slate-200 text-slate-700'}
          `}
        >
          {editMode === 'edit' ? 'Save Changes' : 'Edit Content'}
        </button>

        <button
          onClick={() => setEditMode(editMode === 'add' ? null : 'add')}
          className={`w-12 h-12 rounded-full shadow-lg transition-colors flex items-center justify-center ${
            editMode === 'add'
              ? 'bg-amber-400 text-slate-900'
              : 'bg-slate-900 text-white hover:bg-slate-800'
          }`}
          title={editMode === 'add' ? 'Exit add mode' : 'Add new content'}
        >
          <Plus size={24} />
        </button>

        <button
          onClick={() => setEditMode(editMode === 'delete' ? null : 'delete')}
          className={`w-12 h-12 rounded-full shadow-lg transition-colors flex items-center justify-center ${
            editMode === 'delete'
              ? 'bg-amber-400 text-slate-900'
              : 'bg-slate-900 text-white hover:bg-slate-800'
          }`}
          title={editMode === 'delete' ? 'Exit delete mode' : 'Delete content'}
        >
          <Trash2 size={24} />
        </button>

        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-500 text-white rounded-lg font-medium text-sm"
        >
          Logout
        </button>
      </div>

      <LoginModal isOpen={showLogin} onClose={() => setShowLogin(false)} />
    </>
  );
};

export default AdminControls; 