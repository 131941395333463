import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Create breadcrumb items with proper formatting
  const breadcrumbs = pathnames.map((name, index) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
    const isLast = index === pathnames.length - 1;
    
    // Format the name to be more readable
    const formattedName = name
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return {
      name: formattedName,
      path: routeTo,
      isLast
    };
  });

  // If there are no breadcrumbs (home page), don't render anything
  if (breadcrumbs.length === 0) return null;

  return (
    <nav className="bg-white/80 backdrop-blur-sm border-b border-slate-200 h-[48px] sticky top-[112px] z-30 pt-[5px]" aria-label="Breadcrumb">
      <div className="max-w-7xl mx-auto h-full px-4 sm:px-6 lg:px-8">
        <ol className="flex items-center space-x-2 h-full">
          <li className="flex items-center">
            <Link
              to="/"
              className="text-slate-500 hover:text-slate-900 text-sm font-medium transition-colors flex items-center"
            >
              Home
            </Link>
          </li>
          {breadcrumbs.map(({ name, path, isLast }) => (
            <li key={path} className="flex items-center">
              <ChevronRight className="h-4 w-4 text-slate-400 mx-1" />
              {isLast ? (
                <span className="text-slate-900 text-sm font-semibold flex items-center">{name}</span>
              ) : (
                <Link
                  to={path}
                  className="text-slate-500 hover:text-slate-900 text-sm font-medium transition-colors flex items-center"
                >
                  {name}
                </Link>
              )}
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs; 