import React, { Suspense } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import PageTransition from './PageTransition';
import { useAdminStore } from '../store/adminStore';
import Breadcrumbs from './Breadcrumbs';

interface PageWrapperProps {
  children: React.ReactNode;
}

const pageVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: "easeOut"
    }
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.3,
      ease: "easeIn"
    }
  }
};

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const location = useLocation();
  const { isAdmin } = useAdminStore();

  return (
    <div className="relative w-full min-h-screen overflow-x-hidden">
      <motion.div
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="relative"
      >
        <main className={`relative mt-[112px] ${isAdmin ? 'admin-mode' : ''}`}>
          <Breadcrumbs />
          <div className="mt-0">
            <Suspense
              fallback={
                <div className="flex items-center justify-center min-h-[200px]">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              }
            >
              <PageTransition>
                {children}
              </PageTransition>
            </Suspense>
          </div>
        </main>
      </motion.div>
    </div>
  );
};

export default PageWrapper; 