import { Link } from 'react-router-dom';
import { Phone, Mail, MapPin, Clock, Building2 } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-slate-900 text-white">
      <div className="relative max-w-7xl mx-auto py-8 md:py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Contact Info */}
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-amber-400/10 to-transparent rounded-lg blur opacity-20" />
            <div className="relative">
              <h3 className="text-lg font-semibold mb-4 text-amber-400">Contact Us</h3>
              <div className="space-y-3">
                <a href="tel:+19125372666" className="flex items-center group">
                  <Phone className="w-5 h-5 mr-2 text-amber-400" />
                  <span className="group-hover:text-amber-400 transition-colors">(912) 537-2666</span>
                </a>
                <a href="mailto:susanshook@bellsouth.net" className="flex items-center group">
                  <Mail className="w-5 h-5 mr-2 text-amber-400" />
                  <span className="group-hover:text-amber-400 transition-colors">susanshook@bellsouth.net</span>
                </a>
                <div className="flex items-start">
                  <MapPin className="w-5 h-5 mr-2 text-amber-400 mt-1" />
                  <span>407 Randolph Drive<br />Vidalia, GA 30474</span>
                </div>
                <div className="flex items-start">
                  <Clock className="w-5 h-5 mr-2 text-amber-400 mt-1" />
                  <span className="text-sm">
                    Mon - Thu: 8:00 AM - 5:00 PM<br />
                    Fri: 8:00 AM - 3:00 PM<br />
                    Sat - Sun: Closed
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-amber-400/10 to-transparent rounded-lg blur opacity-20" />
            <div className="relative">
              <h3 className="text-lg font-semibold mb-4 text-amber-400">Quick Links</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/about" className="hover:text-amber-400 transition-colors duration-200">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="hover:text-amber-400 transition-colors duration-200">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/attorneys" className="hover:text-amber-400 transition-colors duration-200">
                    Our Attorneys
                  </Link>
                </li>
                <li>
                  <Link to="/case-results" className="hover:text-amber-400 transition-colors duration-200">
                    Case Results
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Practice Areas */}
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-amber-400/10 to-transparent rounded-lg blur opacity-20" />
            <div className="relative">
              <h3 className="text-lg font-semibold mb-4 text-amber-400">Practice Areas</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/services/personal-injury" className="hover:text-amber-400 transition-colors duration-200">
                    Personal Injury
                  </Link>
                </li>
                <li>
                  <Link to="/services/family-law" className="hover:text-amber-400 transition-colors duration-200">
                    Family Law
                  </Link>
                </li>
                <li>
                  <Link to="/services/business-law" className="hover:text-amber-400 transition-colors duration-200">
                    Business Law
                  </Link>
                </li>
                <li>
                  <Link to="/services/estate-planning" className="hover:text-amber-400 transition-colors duration-200">
                    Estate Planning
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Resources */}
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-amber-400/10 to-transparent rounded-lg blur opacity-20" />
            <div className="relative">
              <h3 className="text-lg font-semibold mb-4 text-amber-400">Resources</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/resources" className="hover:text-amber-400 transition-colors duration-200">
                    Legal Resources
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" className="hover:text-amber-400 transition-colors duration-200">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="hover:text-amber-400 transition-colors duration-200">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-slate-700/50 text-center">
          <div className="flex items-center justify-center gap-2 mb-4">
            <Building2 className="w-5 h-5 text-amber-400" />
            <p className="text-sm text-slate-400">
              Vidalia Law Firm, LLC
            </p>
          </div>
          <p className="text-sm text-slate-400">
            © {new Date().getFullYear()} Vidalia Law Firm. All rights reserved.
          </p>
          <p className="text-[10px] text-slate-500/40 mt-2">
            Website by <a href="https://enviomedia.com" target="_blank" rel="noopener noreferrer" className="hover:text-amber-400/50 transition-colors">enviomedia.com</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;