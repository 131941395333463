import React from 'react';
import { motion } from 'framer-motion';
import { LogOut, Settings } from 'lucide-react';
import { useAdminStore } from '../store/adminStore';

export default function AdminBar() {
  const { isAdmin, logout } = useAdminStore();

  if (!isAdmin) return null;

  return (
    <motion.div
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className="fixed top-0 left-0 right-0 bg-amber-400 text-slate-900 py-2 px-4 flex justify-between items-center z-50 shadow-lg"
    >
      <div className="flex items-center gap-2">
        <Settings className="w-5 h-5" />
        <span className="font-medium">Admin Mode</span>
      </div>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={logout}
        className="flex items-center gap-2 bg-slate-900 text-white px-3 py-1 rounded-full text-sm"
      >
        <LogOut className="w-4 h-4" />
        Logout
      </motion.button>
    </motion.div>
  );
}