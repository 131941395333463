import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AdminState {
  isAdmin: boolean;
  editMode: 'view' | 'edit';
  authToken: string | null;
  content: Record<string, string>;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  toggleEditMode: () => void;
  saveContent: (id: string, content: string) => void;
  getSavedContent: (id: string) => string | null;
}

export const useAdminStore = create<AdminState>()(
  persist(
    (set, get) => ({
      isAdmin: false,
      editMode: 'view',
      authToken: null,
      content: {},

      login: async (username: string, password: string) => {
        try {
          console.log('Attempting login with:', { username });
          
          // For testing - use these credentials
          if (username === 'admin' && password === 'password123') {
            console.log('Login successful');
            set({ 
              isAdmin: true, 
              authToken: 'test-token',
              editMode: 'view'
            });
            return;
          }
          
          throw new Error('Invalid credentials');
        } catch (error) {
          console.error('Login failed:', error);
          throw error;
        }
      },

      logout: () => {
        set({ 
          isAdmin: false, 
          editMode: 'view',
          authToken: null
        });
      },

      toggleEditMode: () => {
        const { isAdmin, editMode } = get();
        if (isAdmin) {
          set({ editMode: editMode === 'view' ? 'edit' : 'view' });
        }
      },

      saveContent: (id: string, content: string) => {
        const { authToken } = get();
        if (!authToken) return;

        set((state) => ({
          content: {
            ...state.content,
            [id]: content,
          },
        }));

        // Optional: Sync with backend
        // fetch('/api/content', {
        //   method: 'POST',
        //   headers: {
        //     'Authorization': `Bearer ${authToken}`,
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ id, content }),
        // });
      },

      getSavedContent: (id: string) => {
        const state = get();
        return state.content[id] || null;
      },
    }),
    {
      name: 'admin-storage-v2',
      partialize: (state) => ({
        isAdmin: state.isAdmin,
        authToken: state.authToken,
        content: state.content
      })
    }
  )
);