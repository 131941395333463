import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useAdminStore } from './store/adminStore';
import Header from './components/Header';
import Footer from './components/Footer';
import ChatInterface from './components/ChatInterface';
import { MessageCircle } from 'lucide-react';
import ContactModal from './components/ContactModal';
import PageWrapper from './components/PageWrapper';
import AdminControls from './components/AdminControls';
import ScrollToTop from './components/ScrollToTop';
import { AnimatePresence, motion } from 'framer-motion';
import AdminBar from './components/AdminBar';
import LoginModal from './components/LoginModal';

// Lazy load page components
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/Services'));
const Attorneys = lazy(() => import('./pages/Attorneys'));
const Resources = lazy(() => import('./pages/Resources'));
const CaseResults = lazy(() => import('./pages/CaseResults'));
const Contact = lazy(() => import('./pages/Contact'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const Disclaimer = lazy(() => import('./pages/Disclaimer'));

// Lazy load service pages
const PersonalInjury = lazy(() => import('./pages/services/PersonalInjury'));
const FamilyLaw = lazy(() => import('./pages/services/FamilyLaw'));
const BusinessLaw = lazy(() => import('./pages/services/BusinessLaw'));
const EstatePlanning = lazy(() => import('./pages/services/EstatePlanning'));
const CriminalLaw = lazy(() => import('./pages/services/CriminalLaw'));

// Lazy load guide pages
const PersonalInjuryGuide = lazy(() => import('./pages/resources/guides/PersonalInjury'));
const EstatePlanningGuide = lazy(() => import('./pages/resources/guides/EstatePlanning'));
const BusinessLawGuide = lazy(() => import('./pages/resources/guides/BusinessLaw'));
const FamilyLawGuide = lazy(() => import('./pages/resources/guides/FamilyLaw'));

function App() {
  const { isAdmin, login, logout, toggleEditMode } = useAdminStore();
  const [showChat, setShowChat] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const location = useLocation();

  // Add event listener for contact form
  useEffect(() => {
    const handleOpenContactForm = () => {
      setIsContactOpen(true);
    };

    window.addEventListener('openContactForm', handleOpenContactForm);
    return () => window.removeEventListener('openContactForm', handleOpenContactForm);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Toggle edit mode with Ctrl + E
      if (event.ctrlKey && event.key.toLowerCase() === 'e' && isAdmin) {
        event.preventDefault();
        toggleEditMode();
      }

      // Logout with Ctrl + L
      if (event.ctrlKey && event.key.toLowerCase() === 'l' && isAdmin) {
        event.preventDefault();
        logout();
      }

      // Open login modal with Option/Alt + Shift + L
      if (event.altKey && event.shiftKey && event.key.toLowerCase() === 'l' && !isLoginModalOpen) {
        event.preventDefault();
        setIsLoginModalOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isAdmin, logout, toggleEditMode, isLoginModalOpen]);

  // Separate keyboard shortcut handler
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // Debug log
      console.log('Key pressed:', {
        key: e.key,
        altKey: e.altKey,
        shiftKey: e.shiftKey,
        code: e.code
      });

      // Check for Option/Alt + Shift + L
      if (e.altKey && e.shiftKey && (e.key === 'L' || e.key === 'l')) {
        e.preventDefault();
        console.log('Opening login modal');
        setIsLoginModalOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Add debug logging
  useEffect(() => {
    const debugKeyPress = (event: KeyboardEvent) => {
      console.log('Key pressed:', {
        key: event.key,
        altKey: event.altKey,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey
      });
    };

    window.addEventListener('keydown', debugKeyPress);
    return () => window.removeEventListener('keydown', debugKeyPress);
  }, []);

  // Log state changes
  useEffect(() => {
    console.log('Login modal state:', isLoginModalOpen);
  }, [isLoginModalOpen]);

  useEffect(() => {
    console.log('Admin state:', isAdmin);
  }, [isAdmin]);

  return (
    <HelmetProvider>
      <div className="relative min-h-screen flex flex-col">
        {isAdmin && <AdminBar />}
        <Header isContactOpen={isContactOpen} setIsContactOpen={setIsContactOpen} />
        
        <main className="flex-grow">
          <PageWrapper>
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/personal-injury" element={<PersonalInjury />} />
                <Route path="/services/family-law" element={<FamilyLaw />} />
                <Route path="/services/business-law" element={<BusinessLaw />} />
                <Route path="/services/estate-planning" element={<EstatePlanning />} />
                <Route path="/services/criminal-law" element={<CriminalLaw />} />
                <Route path="/attorneys" element={<Attorneys />} />
                <Route path="/case-results" element={<CaseResults />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/contact" element={<Contact />} />
                {/* Legal Guide Routes */}
                <Route path="/resources/guides/personal-injury" element={<PersonalInjuryGuide />} />
                <Route path="/resources/guides/estate-planning" element={<EstatePlanningGuide />} />
                <Route path="/resources/guides/business-law" element={<BusinessLawGuide />} />
                <Route path="/resources/guides/family-law" element={<FamilyLawGuide />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
              </Routes>
            </AnimatePresence>
          </PageWrapper>
        </main>

        <Footer />
        
        {/* Chat Button */}
        <motion.button
          onClick={() => setShowChat(true)}
          className="fixed bottom-4 right-4 bg-amber-100 rounded-full p-3 shadow-lg border border-gray-200 cursor-pointer z-50 hover:scale-105 transition-transform"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          animate={{ opacity: showChat ? 0 : 1, scale: showChat ? 0.8 : 1 }}
          transition={{ duration: 0.2 }}
        >
          <MessageCircle className="w-6 h-6 text-amber-600" />
        </motion.button>

        {/* Chat Interface */}
        <AnimatePresence mode="wait">
          {showChat && (
            <ChatInterface onClose={() => setShowChat(false)} />
          )}
        </AnimatePresence>

        <ContactModal
          isOpen={isContactOpen}
          onClose={() => setIsContactOpen(false)}
        />

        {isAdmin && <AdminControls />}

        <ScrollToTop />

        <LoginModal 
          isOpen={isLoginModalOpen} 
          onClose={() => {
            console.log('Closing login modal');
            setIsLoginModalOpen(false);
          }} 
        />
      </div>
    </HelmetProvider>
  );
}

export default App;