import React from 'react';
import { Menu, Phone, MapPin, X, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import Logo from './Logo';

interface HeaderProps {
  isContactOpen: boolean;
  setIsContactOpen: (isOpen: boolean) => void;
}

const services = [
  {
    title: 'Personal Injury',
    description: 'Expert representation for accident victims',
    href: '/services/personal-injury'
  },
  {
    title: 'Family Law',
    description: 'Compassionate family legal services',
    href: '/services/family-law'
  },
  {
    title: 'Business Law',
    description: 'Comprehensive business legal solutions',
    href: '/services/business-law'
  },
  {
    title: 'Estate Planning',
    description: "Secure your family's future",
    href: '/services/estate-planning'
  },
  {
    title: 'Criminal Law',
    description: 'Experienced criminal defense',
    href: '/services/criminal-law'
  }
];

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About', href: '/about' },
  { name: 'Attorneys', href: '/attorneys' },
  { name: 'Case Results', href: '/case-results' },
  { name: 'Resources', href: '/resources' }
];

const servicesDropdown = {
  name: 'Services',
  items: [
    {
      title: 'Personal Injury',
      description: 'Expert representation for accident victims',
      href: '/services/personal-injury'
    },
    {
      title: 'Family Law',
      description: 'Compassionate family legal services',
      href: '/services/family-law'
    },
    {
      title: 'Business Law',
      description: 'Comprehensive business legal solutions',
      href: '/services/business-law'
    },
    {
      title: 'Estate Planning',
      description: "Secure your family's future",
      href: '/services/estate-planning'
    },
    {
      title: 'Criminal Law',
      description: 'Experienced criminal defense',
      href: '/services/criminal-law'
    }
  ]
};

const dropdownVariants = {
  hidden: { 
    opacity: 0,
    y: 10,
    clipPath: 'inset(0% 50% 100% 50% round 10px)',
  },
  visible: {
    opacity: 1,
    y: 0,
    clipPath: 'inset(0% 0% 0% 0% round 10px)',
    transition: {
      type: "spring",
      duration: 0.4,
      staggerChildren: 0.05,
      delayChildren: 0.1
    }
  },
  exit: {
    opacity: 0,
    y: 10,
    clipPath: 'inset(0% 50% 100% 50% round 10px)',
    transition: {
      duration: 0.2,
      ease: "easeIn"
    }
  }
};

const dropdownItemVariants = {
  hidden: { opacity: 0, x: -10 },
  visible: { opacity: 1, x: 0 }
};

const navItemVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.2,
      ease: "easeInOut"
    }
  },
  tap: {
    scale: 0.95,
    transition: {
      duration: 0.1,
      ease: "easeIn"
    }
  }
};

export default function Header({ setIsContactOpen }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [activeDropdown, setActiveDropdown] = React.useState<string | null>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const location = useLocation();

  const topBarHeight = useTransform(scrollY, [0, 50], ["40px", "0px"]);
  const mainNavPadding = useTransform(scrollY, [0, 50], ["16px", "12px"]);
  const bgOpacity = useTransform(scrollY, [0, 50], [0.97, 1]);

  const handleMouseEnter = (dropdown: string) => {
    setActiveDropdown(dropdown);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getCurrentDate = () => {
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date().toLocaleDateString('en-US', options);
  };

  return (
    <motion.header
      className="fixed top-0 left-0 right-0 z-50 h-[100px]"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Top Bar */}
      <motion.div 
        className="bg-slate-900 text-slate-300 relative z-50 hidden md:block"
        style={{ 
          background: `linear-gradient(to right, rgba(15, 23, 42, 1), rgb(15, 23, 42))`,
        }}
      >
        <div className="container mx-auto px-4 py-1">
          <div className="flex items-center justify-between">
            {/* Date on the left */}
            <div className="flex items-center space-x-2 text-slate-400 text-xs">
              <span className="text-amber-400">{getCurrentDate()}</span>
            </div>

            {/* Contact info on the right */}
            <div className="flex items-center space-x-6">
              <motion.a
                href="tel:+19125372666"
                className="flex items-center space-x-2 text-slate-400 hover:text-amber-400 transition-colors group text-xs"
                variants={navItemVariants}
                whileHover="hover"
                whileTap="tap"
              >
                <Phone size={16} className="text-amber-400" />
                <span className="group-hover:text-amber-400 transition-colors">(912) 537-2666</span>
              </motion.a>
              <motion.a
                href="#location"
                className="hidden md:flex items-center space-x-2 text-slate-400 hover:text-amber-400 transition-colors group text-xs"
                variants={navItemVariants}
                whileHover="hover"
                whileTap="tap"
              >
                <MapPin size={16} className="text-amber-400" />
                <span className="group-hover:text-amber-400 transition-colors">407 Randolph Drive, Vidalia, GA 30474</span>
              </motion.a>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Main Navigation */}
      <motion.div
        className="bg-slate-900 relative z-40"
        style={{ 
          background: `linear-gradient(to bottom, rgba(15, 23, 42, 1), rgb(15, 23, 42))`,
        }}
      >
        <div className="container mx-auto px-4 py-2">
          <div className="flex items-center justify-between relative">
            {/* Logo - Centered on mobile */}
            <div className="flex-1 flex justify-center md:justify-start">
              <Link to="/" className="relative">
                <div className="h-[90px]">
                  <Logo className="h-full w-auto" />
                </div>
              </Link>
            </div>

            {/* Mobile Menu Button */}
            <motion.button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-white p-2 absolute right-0 z-50"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </motion.button>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-6">
              {navigation.map((item) => (
                <motion.div
                  key={item.name}
                  variants={navItemVariants}
                  whileHover="hover"
                  className="relative"
                >
                  <Link
                    to={item.href}
                    className={`text-slate-300 transition-colors hover:text-amber-400 text-sm font-medium relative ${
                      location.pathname === item.href ? 'text-amber-400' : ''
                    }`}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  >
                    {item.name}
                    <motion.div
                      className="absolute bottom-0 left-0 right-0 h-0.5 bg-amber-400"
                      initial={{ scaleX: 0, opacity: 0 }}
                      whileHover={{ scaleX: 1, opacity: 1 }}
                      transition={{ duration: 0.3, ease: "easeOut" }}
                    />
                    {location.pathname === item.href && (
                      <motion.div
                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-amber-400"
                        initial={{ scaleX: 1, opacity: 1 }}
                        animate={{ scaleX: 1, opacity: 1 }}
                      />
                    )}
                  </Link>
                </motion.div>
              ))}

              {/* Services Dropdown */}
              <motion.div
                className="relative"
                onMouseEnter={() => handleMouseEnter('services')}
                onMouseLeave={handleMouseLeave}
                ref={dropdownRef}
              >
                <motion.button
                  className={`flex items-center space-x-1 text-slate-300 hover:text-amber-400 transition-colors text-sm font-medium ${
                    location.pathname.includes('/services') ? 'text-amber-400' : ''
                  }`}
                  variants={navItemVariants}
                  whileHover="hover"
                >
                  <span>{servicesDropdown.name}</span>
                  <ChevronDown size={16} className="mt-0.5" />
                </motion.button>

                <AnimatePresence>
                  {activeDropdown === 'services' && (
                    <motion.div
                      variants={dropdownVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="absolute top-full left-0 w-64 bg-white rounded-xl shadow-lg overflow-hidden mt-2"
                    >
                      <div className="p-2">
                        {servicesDropdown.items.map((item) => (
                          <motion.div
                            key={item.title}
                            variants={dropdownItemVariants}
                            className="relative"
                          >
                            <Link
                              to={item.href}
                              className="block p-3 hover:bg-slate-50 rounded-lg transition-colors"
                            >
                              <div className="font-medium text-slate-900">
                                {item.title}
                              </div>
                              <div className="text-sm text-slate-500">
                                {item.description}
                              </div>
                            </Link>
                          </motion.div>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>

              <Link
                to="/contact"
                className="bg-gradient-to-br from-amber-400 to-amber-500 text-slate-900 px-6 py-2 rounded-full hover:from-amber-500 hover:to-amber-600 transition-all duration-300 shadow-md hover:shadow-lg text-sm font-medium"
              >
                Contact Us
              </Link>
            </nav>

            {/* Mobile Navigation Menu */}
            <AnimatePresence>
              {isMenuOpen && (
                <motion.div
                  initial={{ opacity: 0, x: "100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ type: "spring", damping: 25 }}
                  className="fixed inset-0 bg-slate-900 z-40 md:hidden overflow-y-auto"
                >
                  <div className="container mx-auto px-4 py-4">
                    <nav className="flex flex-col space-y-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={`text-slate-400 hover:text-amber-400 transition-colors text-sm font-medium ${
                            location.pathname === item.href ? 'text-amber-400' : ''
                          }`}
                          onClick={() => setIsMenuOpen(false)}
                        >
                          {item.name}
                        </Link>
                      ))}

                      {/* Services Section in Mobile Menu */}
                      <div className="py-2">
                        <div className="text-slate-400 text-sm font-medium mb-2">Services</div>
                        <div className="space-y-2 pl-4">
                          {servicesDropdown.items.map((service) => (
                            <Link
                              key={service.title}
                              to={service.href}
                              className="block text-slate-400 hover:text-amber-400 transition-colors text-sm"
                              onClick={() => setIsMenuOpen(false)}
                            >
                              {service.title}
                            </Link>
                          ))}
                        </div>
                      </div>

                      <div className="pt-2">
                        <Link
                          to="/contact"
                          onClick={() => setIsMenuOpen(false)}
                          className="block w-full bg-gradient-to-br from-amber-400 to-amber-500 text-slate-900 px-6 py-2 rounded-full hover:from-amber-500 hover:to-amber-600 transition-all duration-300 shadow-md hover:shadow-lg text-sm font-medium text-center"
                        >
                          Contact Us
                        </Link>
                      </div>
                    </nav>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </motion.header>
  );
}