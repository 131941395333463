import React from 'react';

interface LogoProps {
  className?: string;
  width?: number;
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ className = '', width = 280, height = 90 }) => {
  return (
    <img
      src="/images/logo.png"
      alt="Salter Shook - Attorneys At Law"
      className={`${className} transition-all duration-200`}
      style={{
        width: 'auto',
        height: '100%',
        maxHeight: height,
        objectFit: 'contain',
        filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1))'
      }}
    />
  );
};

export default Logo; 