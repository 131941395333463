import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Phone, Mail, MapPin, Clock, Send } from 'lucide-react';

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ContactModal({ isOpen, onClose }: ContactModalProps) {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    caseType: '',
    message: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        />

        {/* Modal panel */}
        <div className="inline-block transform overflow-hidden rounded-lg bg-[#15191E] text-white px-8 pt-5 pb-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:align-middle">
          <div className="absolute right-0 top-0 pr-4 pt-4">
            <button
              type="button"
              className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column - Contact Information */}
            <div>
              <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
              <p className="text-gray-400 mb-8 text-sm leading-relaxed">
                Fill out this short form and a Salter • Shook Attorneys At Law representative will contact you within 2-3 business days to schedule your consultation. If you need immediate assistance, please call us at (912) 537-2666.
              </p>

              <div className="space-y-6">
                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 rounded-full bg-[#1E2329] flex items-center justify-center">
                    <Phone className="h-5 w-5 text-[#FDB813]" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Phone</p>
                    <a href="tel:+19125372666" className="text-white hover:text-gray-300">
                      (912) 537-2666
                    </a>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 rounded-full bg-[#1E2329] flex items-center justify-center">
                    <Mail className="h-5 w-5 text-[#FDB813]" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Email</p>
                    <a href="mailto:susanshook@bellsouth.net" className="text-white hover:text-gray-300">
                      susanshook@bellsouth.net
                    </a>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 rounded-full bg-[#1E2329] flex items-center justify-center">
                    <MapPin className="h-5 w-5 text-[#FDB813]" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Office</p>
                    <p className="text-white">407 Randolph Drive</p>
                    <p className="text-white">Vidalia, GA 30474</p>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 rounded-full bg-[#1E2329] flex items-center justify-center">
                    <Clock className="h-5 w-5 text-[#FDB813]" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Hours</p>
                    <p className="text-white">Mon - Thu: 8:00 AM - 5:00 PM</p>
                    <p className="text-white">Fri: 8:00 AM - 3:00 PM</p>
                    <p className="text-white">Sat - Sun: Closed</p>
                    <p className="text-[#FDB813] text-sm mt-1">Evening and weekend consultations available by appointment.</p>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <h3 className="text-lg font-semibold mb-2">Need Immediate Assistance?</h3>
                <p className="text-gray-400">Call us at:</p>
                <a href="tel:+19125372666" className="text-[#FDB813] text-lg font-semibold">
                  (912) 537-2666
                </a>
              </div>
            </div>

            {/* Right Column - Form */}
            <div>
              <h2 className="text-2xl font-semibold mb-6">Send us a Message</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="fullName" className="block text-sm mb-2">
                    Full Name <span className="text-[#FDB813]">*</span>
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    placeholder="John Doe"
                    className="w-full px-4 py-3 bg-white text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FDB813]"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm mb-2">
                    Email Address <span className="text-[#FDB813]">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="john@example.com"
                    className="w-full px-4 py-3 bg-white text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FDB813]"
                    required
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="phone" className="block text-sm mb-2">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="(123) 456-7890"
                      className="w-full px-4 py-3 bg-white text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FDB813]"
                    />
                  </div>

                  <div>
                    <label htmlFor="caseType" className="block text-sm mb-2">
                      Case Type <span className="text-[#FDB813]">*</span>
                    </label>
                    <select
                      id="caseType"
                      name="caseType"
                      value={formData.caseType}
                      onChange={handleChange}
                      className="w-full px-4 py-3 bg-white text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FDB813]"
                      required
                    >
                      <option value="">Select a case type</option>
                      <option value="personal-injury">Personal Injury</option>
                      <option value="family-law">Family Law</option>
                      <option value="estate-planning">Estate Planning</option>
                      <option value="business-law">Business Law</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm mb-2">
                    Your Message <span className="text-[#FDB813]">*</span>
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Please describe your case briefly..."
                    rows={4}
                    className="w-full px-4 py-3 bg-white text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FDB813] resize-none"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#FDB813] text-[#15191E] py-3 px-4 rounded-md font-medium hover:bg-[#E5A711] transition-colors duration-200 flex items-center justify-center gap-2"
                >
                  Send Message
                  <Send className="h-5 w-5" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}