import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, History, Download, X, User, MessageSquare, Bell, Loader2 } from 'lucide-react';

interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
  timestamp: Date;
  isTyping?: boolean;
}

interface ChatInterfaceProps {
  onClose?: () => void;
}

export default function ChatInterface({ onClose }: ChatInterfaceProps) {
  const [messages, setMessages] = useState<Message[]>([{
    id: Date.now().toString(),
    content: "Hello! I'm your legal assistant. How can I help you today?",
    role: 'assistant',
    timestamp: new Date()
  }]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const hasInteracted = useRef(false);
  const notificationRef = useRef<HTMLDivElement>(null);

  // Load chat history from localStorage
  useEffect(() => {
    const savedHistory = localStorage.getItem('chatHistory');
    if (savedHistory) {
      try {
        const parsedHistory = JSON.parse(savedHistory);
        // Convert timestamp strings back to Date objects
        const messagesWithDates = parsedHistory.map((msg: any) => ({
          ...msg,
          timestamp: new Date(msg.timestamp)
        }));
        setMessages(messagesWithDates);
        if (messagesWithDates.length > 0) {
          hasInteracted.current = true;
          setIsChatVisible(true);
        }
      } catch (e) {
        console.error('Error parsing chat history:', e);
      }
    }
  }, []);

  // Save chat history to localStorage
  useEffect(() => {
    localStorage.setItem('chatHistory', JSON.stringify(messages));
  }, [messages]);

  // Add notification timer
  useEffect(() => {
    // Only start the notification timer if chat is not visible
    if (!isChatVisible) {
      const notificationTimer = setTimeout(() => {
        if (!hasInteracted.current) {
          setShowNotification(true);
        }
      }, 5000); // Reduced to 5 seconds for testing

      return () => clearTimeout(notificationTimer);
    } else {
      setShowNotification(false);
    }
  }, [isChatVisible]);

  const handleInteraction = () => {
    hasInteracted.current = true;
    setShowNotification(false);
  };

  const handleBellClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsChatVisible(true);
    handleInteraction();
  };

  const handleCloseChat = () => {
    setIsChatVisible(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const simulateTyping = async (text: string) => {
    // Calculate delay based on message length (longer messages take longer to type)
    const baseDelay = 1000; // minimum delay of 1 second
    const charDelay = 20; // additional ms per character
    const delay = Math.min(baseDelay + text.length * charDelay, 4000); // cap at 4 seconds
    await new Promise(resolve => setTimeout(resolve, delay));
    return text;
  };

  const handleSend = async () => {
    handleInteraction();
    if (!input.trim() || isLoading) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      content: input,
      role: 'user',
      timestamp: new Date(),
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    // Add typing indicator message
    const typingMessage: Message = {
      id: 'typing',
      content: '',
      role: 'assistant',
      timestamp: new Date(),
      isTyping: true
    };
    setMessages(prev => [...prev, typingMessage]);

    try {
      // Simple response based on keywords in the input
      let response = '';
      const lowerInput = input.toLowerCase();

      if (lowerInput.includes('personal injury')) {
        response = 'For personal injury cases, please contact our Personal Injury department. You can reach us at (912) 537-2666 or visit our Personal Injury page for more information.';
      } else if (lowerInput.includes('family law') || lowerInput.includes('divorce') || lowerInput.includes('custody')) {
        response = 'Our Family Law department handles divorce, child custody, and other family-related legal matters. Call us at (912) 537-2666 or visit our Family Law page for details.';
      } else if (lowerInput.includes('business') || lowerInput.includes('corporate')) {
        response = 'For business and corporate law matters, our Business Law department is here to help. Contact us at (912) 537-2666 or visit our Business Law page.';
      } else if (lowerInput.includes('criminal')) {
        response = 'Our Criminal Law department provides defense for various criminal charges. Call us at (912) 537-2666 or visit our Criminal Law page for more information.';
      } else if (lowerInput.includes('estate') || lowerInput.includes('will') || lowerInput.includes('trust')) {
        response = 'For estate planning, wills, and trusts, please contact our Estate Planning department. Reach us at (912) 537-2666 or visit our Estate Planning page.';
      } else if (lowerInput.includes('contact') || lowerInput.includes('phone') || lowerInput.includes('email')) {
        response = 'You can reach us at:\nPhone: (912) 537-2666\nEmail: info@vidalialaw.com\nAddress: 101 Jackson Street, Vidalia, GA 30474\nWe offer free initial consultations.';
      } else {
        response = 'I can help you find information about our legal services. Please let me know if you\'re interested in:\n- Personal Injury\n- Family Law\n- Business Law\n- Criminal Law\n- Estate Planning\n\nOr call us directly at (912) 537-2666 for immediate assistance.';
      }

      // Simulate typing delay
      await simulateTyping(response);

      // Remove typing indicator and add real response
      setMessages(prev => prev.filter(msg => !msg.isTyping));

      const assistantMessage: Message = {
        id: Date.now().toString(),
        content: response,
        role: 'assistant',
        timestamp: new Date(),
      };

      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => prev.filter(msg => !msg.isTyping));
      const errorMessage: Message = {
        id: Date.now().toString(),
        content: 'I apologize, but I\'m having trouble processing your request. Please contact our office directly at (912) 537-2666.',
        role: 'assistant',
        timestamp: new Date(),
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const exportChatHistory = () => {
    const history = JSON.stringify(messages, null, 2);
    const blob = new Blob([history], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `chat-history-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="fixed bottom-4 right-4 flex items-center">
        <AnimatePresence>
          {showNotification && !isChatVisible && (
            <motion.div
              initial={{ opacity: 0, x: -20, width: 0 }}
              animate={{ 
                opacity: 1, 
                x: 0,
                width: "auto",
                transition: {
                  duration: 0.3,
                  ease: "easeOut"
                }
              }}
              exit={{ 
                opacity: 0, 
                x: -20, 
                width: 0,
                transition: {
                  duration: 0.2,
                  ease: "easeIn"
                }
              }}
              className="bg-white rounded-l-xl shadow-lg py-4 pl-4 pr-2 border border-r-0 border-gray-200 cursor-pointer z-50 overflow-hidden"
              onClick={handleBellClick}
            >
              <motion.div
                animate={{
                  y: [0, -4, 0],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="flex items-center gap-3 whitespace-nowrap"
              >
                <div className="flex-1">
                  <h3 className="font-medium text-gray-900">Need Legal Assistance?</h3>
                  <p className="text-sm text-gray-600 mt-0.5">Click to chat with our legal assistant</p>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        
        <motion.div
          className="bg-amber-100 rounded-full p-3 shadow-lg border border-gray-200 cursor-pointer z-50"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleBellClick}
        >
          <Bell className="w-6 h-6 text-amber-600" />
        </motion.div>
      </div>
      
      <AnimatePresence>
        {isChatVisible && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            transition={{ duration: 0.2 }}
            className="fixed bottom-20 right-4 w-[380px] h-[600px] bg-white rounded-2xl shadow-2xl flex flex-col overflow-hidden border border-gray-200 z-40"
          >
            {/* Header */}
            <div className="flex items-center justify-between px-6 py-4 bg-gradient-to-r from-amber-500 to-amber-600 text-white">
              <div className="flex items-center gap-2">
                <MessageSquare className="w-5 h-5" />
                <h2 className="text-lg font-semibold">Legal Assistant</h2>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setShowHistory(!showHistory)}
                  className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  title="Chat History"
                >
                  <History className="w-5 h-5" />
                </button>
                {onClose && (
                  <button
                    onClick={onClose}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>

            {/* Messages */}
            <div className="flex-1 overflow-y-auto p-6 space-y-4 bg-gray-50">
              <AnimatePresence>
                {messages.map((message) => (
                  <motion.div
                    key={message.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className={`flex items-end gap-2 ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                  >
                    {message.role === 'assistant' && (
                      <div className="w-8 h-8 rounded-full bg-amber-100 flex items-center justify-center flex-shrink-0">
                        <MessageSquare className="w-4 h-4 text-amber-600" />
                      </div>
                    )}
                    <div
                      className={`max-w-[75%] rounded-2xl p-4 ${
                        message.role === 'user'
                          ? 'bg-gradient-to-br from-amber-500 to-amber-600 text-white rounded-br-sm'
                          : 'bg-white text-gray-800 shadow-sm rounded-bl-sm border border-gray-100'
                      }`}
                    >
                      {message.isTyping ? (
                        <div className="flex items-center gap-2">
                          <motion.div
                            animate={{ opacity: [0.4, 1, 0.4] }}
                            transition={{ duration: 1.5, repeat: Infinity }}
                          >
                            <Loader2 className="w-4 h-4 animate-spin text-amber-600" />
                          </motion.div>
                          <span className="text-sm text-gray-500">Typing...</span>
                        </div>
                      ) : (
                        <>
                          <div className="whitespace-pre-line text-sm leading-relaxed">
                            {message.content}
                          </div>
                          <div className={`text-[10px] mt-1 ${
                            message.role === 'user' ? 'text-amber-100' : 'text-gray-400'
                          }`}>
                            {new Date(message.timestamp).toLocaleTimeString()}
                          </div>
                        </>
                      )}
                    </div>
                    {message.role === 'user' && (
                      <div className="w-8 h-8 rounded-full bg-amber-500 flex items-center justify-center flex-shrink-0">
                        <User className="w-4 h-4 text-white" />
                      </div>
                    )}
                  </motion.div>
                ))}
              </AnimatePresence>
              <div ref={messagesEndRef} />
            </div>

            {/* Input */}
            <div className="p-4 bg-white border-t border-gray-100">
              <div className="flex items-center gap-2">
                <textarea
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your message..."
                  className="flex-1 p-3 border border-gray-200 rounded-xl resize-none focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-transparent text-sm"
                  rows={1}
                />
                <button
                  onClick={handleSend}
                  disabled={isLoading || !input.trim()}
                  className={`p-3 rounded-xl transition-colors ${
                    isLoading || !input.trim()
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-gradient-to-r from-amber-500 to-amber-600 text-white hover:from-amber-600 hover:to-amber-700'
                  }`}
                >
                  <Send className="w-5 h-5" />
                </button>
              </div>
            </div>

            {/* History Panel */}
            <AnimatePresence>
              {showHistory && (
                <motion.div
                  initial={{ opacity: 0, y: '100%' }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: '100%' }}
                  transition={{ duration: 0.2 }}
                  className="absolute inset-0 bg-white"
                >
                  <div className="flex justify-between items-center p-6 border-b border-gray-100">
                    <h3 className="text-lg font-semibold text-gray-800">Chat History</h3>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={exportChatHistory}
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                        title="Export History"
                      >
                        <Download className="w-5 h-5 text-gray-600" />
                      </button>
                      <button
                        onClick={() => setShowHistory(false)}
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                      >
                        <X className="w-5 h-5 text-gray-600" />
                      </button>
                    </div>
                  </div>
                  <div className="p-4 space-y-2 max-h-[calc(100%-80px)] overflow-y-auto">
                    {messages.map((message) => (
                      <motion.div
                        key={message.id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="p-3 hover:bg-gray-50 rounded-xl cursor-pointer transition-colors border border-gray-100"
                        onClick={() => {
                          setInput(message.content);
                          setShowHistory(false);
                        }}
                      >
                        <div className="flex items-center gap-2 mb-1">
                          <div className={`w-6 h-6 rounded-full flex items-center justify-center ${
                            message.role === 'user' ? 'bg-amber-500' : 'bg-amber-100'
                          }`}>
                            {message.role === 'user' ? (
                              <User className="w-3 h-3 text-white" />
                            ) : (
                              <MessageSquare className="w-3 h-3 text-amber-600" />
                            )}
                          </div>
                          <div className="text-sm font-medium text-gray-700">
                            {message.role === 'user' ? 'You' : 'Assistant'}
                          </div>
                          <div className="text-xs text-gray-400">
                            {new Date(message.timestamp).toLocaleString()}
                          </div>
                        </div>
                        <div className="text-sm text-gray-600 line-clamp-2 pl-8">
                          {message.content}
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
} 